import axios from 'axios';
import {
    DATASET_LIST_REQUEST,
    DATASET_LIST_SUCCESS,
    DATASET_LIST_FAIL,
    DATASET_DETAILS_REQUEST,
    DATASET_DETAILS_SUCCESS,
    DATASET_DETAILS_FAIL,
    DATASET_DELETE_REQUEST,
    DATASET_DELETE_SUCCESS,
    DATASET_DELETE_FAIL,
    DATASET_CREATE_REQUEST,
    DATASET_CREATE_SUCCESS,
    DATASET_CREATE_FAIL,
    DATASET_UPDATE_REQUEST,
    DATASET_UPDATE_SUCCESS,
    DATASET_UPDATE_FAIL,
    DATASET_UPLOAD_REQUEST,
    DATASET_UPLOAD_SUCCESS,
    DATASET_UPLOAD_FAIL,
    DATASET_LIST_MY_REQUEST,
    DATASET_LIST_MY_SUCCESS,
    DATASET_LIST_MY_FAIL
} from '../constants/datasetConstants';
import { DATASETS_URL } from '../constants/apiConstants';

export const listDatasets = (keyword = '', pageNumber = '') => async (dispatch) => {
    try {
        dispatch({ type: DATASET_LIST_REQUEST });

        const { data } = await axios.get(`${DATASETS_URL}?keyword=${keyword}&pageNumber=${pageNumber}`);

        dispatch({
            type: DATASET_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DATASET_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listDatasetDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: DATASET_DETAILS_REQUEST });

        const { data } = await axios.get(`${DATASETS_URL}/${id}`);

        dispatch({
            type: DATASET_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DATASET_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const deleteDataset = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DATASET_DELETE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`${DATASETS_URL}/${id}`, config);

        dispatch({
            type: DATASET_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: DATASET_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const createDataset = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: DATASET_CREATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`${DATASETS_URL}`, {}, config);

        dispatch({
            type: DATASET_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DATASET_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateDataset = (dataset) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DATASET_UPDATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`${DATASETS_URL}/${dataset._id}`, dataset, config);

        dispatch({
            type: DATASET_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DATASET_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const uploadDataset = (datasetData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DATASET_UPLOAD_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`${DATASETS_URL}`, datasetData, config);

        dispatch({
            type: DATASET_UPLOAD_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DATASET_UPLOAD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};









export const listMyDatasets = () => async (dispatch, getState) => {
    try {
      dispatch({ type: DATASET_LIST_MY_REQUEST });
  
      const { userLogin: { userInfo } } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get('/api/datasets/mydatasets', config);
  
      dispatch({
        type: DATASET_LIST_MY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DATASET_LIST_MY_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };