import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { logout } from '../actions/userActions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Header = () => {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const logoutHandler = () => {
        dispatch(logout());
    };

    return (
        // <header>
        //     <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
        //         <Container>
        //             <LinkContainer to='/'>
        //                 <Navbar.Brand>Data Marketplace</Navbar.Brand>
        //             </LinkContainer>
        //             <Navbar.Toggle aria-controls='basic-navbar-nav' />
        //             <Navbar.Collapse id='basic-navbar-nav'>
        //                 <Nav className='ml-auto'>
        //                     <LinkContainer to='/datasets'>
        //                         <Nav.Link>
        //                             <i className='fas fa-database'></i> Datasets
        //                         </Nav.Link>
        //                     </LinkContainer>
        //                     {userInfo ? (
        //                         <NavDropdown title={userInfo.name} id='username'>
        //                             <LinkContainer to='/profile'>
        //                                 <NavDropdown.Item>Profile</NavDropdown.Item>
        //                             </LinkContainer>
        //                             <LinkContainer to='/upload'>
        //                                 <NavDropdown.Item>Upload Dataset</NavDropdown.Item>
        //                             </LinkContainer>
        //                             <NavDropdown.Item onClick={logoutHandler}>
        //                                 Logout
        //                             </NavDropdown.Item>
        //                         </NavDropdown>
        //                     ) : (
        //                         <LinkContainer to='/login'>
        //                             <Nav.Link>
        //                                 <i className='fas fa-user'></i> Sign In
        //                             </Nav.Link>
        //                         </LinkContainer>
        //                     )}
        //                 </Nav>
        //             </Navbar.Collapse>
        //         </Container>
        //     </Navbar>
        // </header>
        <header id="header" className="main-header">
            <div className="container">
                <nav className="navbar navbar-light">
                    <div className="container">
                    <a className="navbar-brand" href="/">
                            <img className="logo" src="img/logo.png" alt="image"/>
                    </a>
                    
                    <div id="navbarText">
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Features</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Pricing</a>
                        </li>
                        </ul> */}
                        <div>
                            <div className="btn-purchase ">
                                <Link to='/login' className="button white-btn shadow">Login Now</Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;