import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { uploadDataset } from '../actions/datasetActions';
import { DATASET_UPLOAD_RESET } from '../constants/datasetConstants';
import { validateDatasetTitle, validateDatasetDescription, validateDatasetPrice } from '../utils/validation';

const UploadDatasetScreen = ({ history }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(null);

    const dispatch = useDispatch();

    const datasetUpload = useSelector((state) => state.datasetUpload);
    const { loading, error, success, dataset } = datasetUpload;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            history.push('/login');
        }
        if (success) {
            dispatch({ type: DATASET_UPLOAD_RESET });
            history.push(`/dataset/${dataset._id}`);
        }
    }, [dispatch, history, userInfo, success, dataset]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (!validateDatasetTitle(title)) {
            setMessage('Title should be between 3 and 100 characters');
        } else if (!validateDatasetDescription(description)) {
            setMessage('Description should be between 10 and 1000 characters');
        } else if (!validateDatasetPrice(price)) {
            setMessage('Price should be a non-negative number');
        } else if (!file) {
            setMessage('Please select a file to upload');
        } else {
            setMessage(null);
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('price', price);
            formData.append('file', file);
            dispatch(uploadDataset(formData));
        }
    };

    return (
        <FormContainer>
            <h1>Upload Dataset</h1>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='title'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='description'>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='Enter description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='price'>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Enter price'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='file'>
                    <Form.Label>File</Form.Label>
                    <Form.File
                        id='file'
                        label='Choose File'
                        custom
                        onChange={(e) => setFile(e.target.files[0])}
                    ></Form.File>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    Upload
                </Button>
            </Form>
        </FormContainer>
    );
};

export default UploadDatasetScreen;