import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listDatasetDetails } from '../actions/datasetActions';

const DatasetScreen = ({ match, history }) => {
    const dispatch = useDispatch();

    const datasetDetails = useSelector((state) => state.datasetDetails);
    const { loading, error, dataset } = datasetDetails;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        dispatch(listDatasetDetails(match.params.id));
    }, [dispatch, match]);

    const purchaseHandler = () => {
        history.push(`/purchase/${match.params.id}`);
    };

    return (
        <>
            <Link className='btn btn-light my-3' to='/'>
                Go Back
            </Link>
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <Row>
                    <Col md={6}>
                        <Image src={dataset.fileUrl} alt={dataset.title} fluid />
                    </Col>
                    <Col md={3}>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h3>{dataset.title}</h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Uploaded by: {dataset.owner && dataset.owner.name}
                            </ListGroup.Item>
                            <ListGroup.Item>Price: ${dataset.price}</ListGroup.Item>
                            <ListGroup.Item>
                                Description: {dataset.description}
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Price:</Col>
                                        <Col>
                                            <strong>${dataset.price}</strong>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Button
                                        onClick={purchaseHandler}
                                        className='btn-block'
                                        type='button'
                                        disabled={!userInfo}
                                    >
                                        Purchase Dataset
                                    </Button>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default DatasetScreen;