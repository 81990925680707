import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { verifyEmail } from '../actions/userActions';

const EmailVerificationScreen = ({ match, history }) => {
    const token = match.params.token;

    const dispatch = useDispatch();

    const userVerifyEmail = useSelector((state) => state.userVerifyEmail);
    const { loading, error, success } = userVerifyEmail;

    useEffect(() => {
        if (token) {
            dispatch(verifyEmail(token));
        }
    }, [dispatch, token]);

    return (

        <div className="main-content pt-5">
            <div className='my-5 py-5'>
        <Row className='justify-content-md-center'>
            <Col xs={12} md={6}>
                <h1>Email Verification</h1>
                {loading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error}</Message>
                ) : success ? (
                    <Message variant='success'>
                        Your email has been verified successfully. You can now{' '}
                        <Link to='/login'>login</Link>.
                    </Message>
                ) : (
                    <Message variant='info'>Verifying your email...</Message>
                )}
            </Col>
        </Row>

        </div>

        </div>
    );
};

export default EmailVerificationScreen;