
import React, { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { listMyDatasets } from '../actions/datasetActions';
import { listMyPurchases } from '../actions/purchaseActions';
import Loader from '../components/Loader';
import Message from '../components/Message';

const DashboardScreen = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const datasetListMy = useSelector(state => state.datasetListMy);
  const { loading: loadingDatasets, error: errorDatasets, datasets } = datasetListMy;

  const purchaseListMy = useSelector(state => state.purchaseListMy);
  const { loading: loadingPurchases, error: errorPurchases, purchases } = purchaseListMy;

  useEffect(() => {
    dispatch(listMyDatasets());
    dispatch(listMyPurchases());
  }, [dispatch]);

  return (
    <>
      <h1>Dashboard</h1>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>My Listings</Card.Title>
              {loadingDatasets ? (
                <Loader />
              ) : errorDatasets ? (
                <Message variant='danger'>{errorDatasets}</Message>
              ) : (
                <>
                  <Card.Text>Total Listings: {datasets.length}</Card.Text>
                  <Link to='/create-listing' className='btn btn-primary'>
                    Create New Listing
                  </Link>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>My Purchases</Card.Title>
              {loadingPurchases ? (
                <Loader />
              ) : errorPurchases ? (
                <Message variant='danger'>{errorPurchases}</Message>
              ) : (
                <Card.Text>Total Purchases: {purchases.length}</Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DashboardScreen;