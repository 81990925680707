import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { forgotPassword } from '../actions/userActions';
import { validateEmail } from '../utils/validation';

const ForgotPasswordScreen = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);

    const dispatch = useDispatch();

    const userForgotPassword = useSelector((state) => state.userForgotPassword);
    const { loading, error, success } = userForgotPassword;

    useEffect(() => {
        if (success) {
            setMessage('Password reset email sent. Please check your inbox.');
        }
    }, [success]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setMessage('Please enter a valid email address');
        } else {
            dispatch(forgotPassword(email));
        }
    };

    return (
        <div className="main-content pt-5">
            <div className='my-5 py-5'>
        <FormContainer>
            <h4 className='text-center border-bottom-ef py-3 mb-3'>Forgot Password</h4>
            {message && <Message variant='info'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Enter email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary' className='mt-3 shadow w-100  py-2'>
                    Send Reset Link
                </Button>
            </Form>
        </FormContainer>
        </div>
        </div>
    );
};

export default ForgotPasswordScreen;