export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
    // At least 8 characters, 1 uppercase, 1 lowercase, 1 number
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
};

export const validateName = (name) => {
    return name.length >= 2;
};

export const validateDatasetTitle = (title) => {
    return title.length >= 3 && title.length <= 100;
};

export const validateDatasetDescription = (description) => {
    return description.length >= 10 && description.length <= 1000;
};

export const validateDatasetPrice = (price) => {
    return !isNaN(price) && price >= 0;
};