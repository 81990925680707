import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Dataset from '../components/Dataset';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import { listDatasets } from '../actions/datasetActions';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const HomeScreen = ({ match }) => {
    const keyword = match.params.keyword || '';
    const pageNumber = match.params.pageNumber || 1;

    const dispatch = useDispatch();

    const datasetList = useSelector((state) => state.datasetList);
    const { loading, error, datasets, page, pages } = datasetList;

    const [faqindex, setFaqIndex] = useState(0);

    useEffect(() => {
        dispatch(listDatasets(keyword, pageNumber));
    }, [dispatch, keyword, pageNumber]);

    const particlesInit = async (main) => {
        console.log(main);
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
        console.log(container);
    };

    return (
        <>
            {/* <h1>Latest Datasets</h1>
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <>
                    <Row>
                        {datasets.map((dataset) => (
                            <Col key={dataset._id} sm={12} md={6} lg={4} xl={3}>
                                <Dataset dataset={dataset} />
                            </Col>
                        ))}
                    </Row>
                    <Paginate
                        pages={pages}
                        page={page}
                        keyword={keyword ? keyword : ''}
                    />
                </>
            )} */}




            {/* <section id="home" className="iq-banner">
        <div className="container">
            <div className="row ">
                <div className="col-sm-12">
                   
                    <div className="iq-banner-info text-center ">
                        <h1 className="text-primary">Data Marketplace</h1>
                        <div className="title-box mb-0">
                            <h2> Data Science & Analytics
                                HTML , Vue & React Template</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <p>Xamin comes with amazing customization features. With full creative control over its
                                    appearance, Xamin offers various options to create your website.Xamin can be used to
                                    create an interactive website. The effects used in this HTML theme can give an
                                    impressive look to your brand.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section> */}


            <section id="hero" className="hero section">
                <div class="overlay-photo-image-bg" style={{backgroundImage: 'url("img/pattern-bg.jpg")'}}></div>
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push"
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse"
                                },
                                resize: true
                            },
                            modes: {
                                push: {
                                    quantity: 4
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4
                                }
                            }
                        },
                        particles: {
                            color: {
                                value: "#ffffff"
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1
                            },
                            collisions: {
                                enable: true
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outModes: {
                                    default: "bounce"
                                },
                                random: false,
                                speed: 1,
                                straight: false
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800
                                },
                                value: 10
                            },
                            opacity: {
                                value: 0.5
                            },
                            shape: {
                                type: "circle"
                            },
                            size: {
                                value: { min: 1, max: 5 }
                            }
                        },
                        detectRetina: true
                    }}
                />

                <div>
                    <div className="container pt-md-5">
                        <div className="row gy-4 py-5">
                            <div className="col-lg-7 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <div className="social-links mb-3">
                                <a href="https://facebook.com/datamarketplace" className='text-white me-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-meta"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 10.174c1.766 -2.784 3.315 -4.174 4.648 -4.174c2 0 3.263 2.213 4 5.217c.704 2.869 .5 6.783 -2 6.783c-1.114 0 -2.648 -1.565 -4.148 -3.652a27.627 27.627 0 0 1 -2.5 -4.174z" /><path d="M12 10.174c-1.766 -2.784 -3.315 -4.174 -4.648 -4.174c-2 0 -3.263 2.213 -4 5.217c-.704 2.869 -.5 6.783 2 6.783c1.114 0 2.648 -1.565 4.148 -3.652c1 -1.391 1.833 -2.783 2.5 -4.174z" /></svg>
                                </a>
                                <a href="https://twitter.com/@DataMarketHQ" className='text-white me-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" /></svg>
                                </a>
                                <a href="https://linkedin.com/DataMarketplace" className='text-white me-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-linkedin"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M8 11l0 5" /><path d="M8 8l0 .01" /><path d="M12 16l0 -5" /><path d="M16 16v-3a2 2 0 0 0 -4 0" /></svg>
                                </a>
                                <a href="https://instagram.com/@datamarketplace_official" className='text-white me-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
                                </a>

                            </div>
                                <h1 className='text-white mb-3'>Your Data. Your Terms. Your Revenue.</h1>
                                <p className='pe-5 text-white'>In today's digital economy, data is one of the most valuable commodities. The global big data and analytics market is projected to reach $655 billion by 2029 (Fortune Business Insights, 2022). But ask yourself: Who is profiting from your data? Don't let others capitalize on your valuable information. Take control of your data commercialization and start generating revenue today</p>
                                <div className='mt-3'>
                                    <Link to='/login' className="button white-btn shadow px-5">Login Now</Link>
                                </div>
                            </div>
                            <div className="col-lg-5 order-1 order-lg-2 hero-img aos-init aos-animate">
                                <img src="img/hero-img.png" className="img-fluid animated" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <div className="main-content">

                <section id="services" className="services section">


                    <div className="container section-title aos-init aos-animate pb-4" data-aos="fade-up">
                        <h2 className='text-center pb-2'>Why Choose Our Data Marketplace?</h2>
                    </div>
                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-activity">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M3 12h4l3 8l4 -16l3 8h4" />
                                        </svg>
                                    </div>
                                    <h5 className='mb-3'>Seize Control of Your Data Commercialization</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>- Direct Transactions: Bypass intermediaries and gatekeepers. Connect directly with businesses that need your data.</li>
                                        <li className='list-item'>- SetA Your Own Terms: You decide who buys your data, how it's used, and under what conditions</li>
                                        <li className='list-item'>- Full Transparency: Know exactly who is accessing your data and for what purpose.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-math-max-min"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M3 14s.605 -5.44 2.284 -7.862m3.395 .026c2.137 2.652 4.547 9.113 6.68 11.719" /><path d="M18.748 18.038c.702 -.88 1.452 -3.56 2.252 -8.038" /></svg>
                                    </div>
                                    <h5 className='mb-3'>Maximize Your Revenue Potential</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>- Name Your Price: Understand the true value of your data and price it accordingly to maximize earnings.</li>
                                        <li className='list-item'>- Multiple Revenue Streams: Sell your data to multiple buyers or offer exclusive rights for premium pricing.</li>
                                        <li className='list-item'>- Performance Analytics: Track your sales and optimize your listings for better visibility and higher income.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-world"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M3.6 9h16.8" /><path d="M3.6 15h16.8" /><path d="M11.5 3a17 17 0 0 0 0 18" /><path d="M12.5 3a17 17 0 0 1 0 18" /></svg>
                                    </div>
                                    <h5 className='mb-3'>Global Reach and Unparalleled Exposure</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>- Worldwide Network: Access a vast marketplace of businesses across industries and continents seeking diverse data</li>
                                        <li className='list-item'>- Stay Competitive: Leverage global demand to ensure you're getting the best price for your data.</li>
                                        <li className='list-item'>- Market Insights: Utilize our platform's analytics to identify trends and high-demand data categories.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-cloud-lock"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M19 18a3.5 3.5 0 0 0 0 -7h-1c.397 -1.768 -.285 -3.593 -1.788 -4.787c-1.503 -1.193 -3.6 -1.575 -5.5 -1s-3.315 2.019 -3.712 3.787c-2.199 -.088 -4.155 1.326 -4.666 3.373c-.512 2.047 .564 4.154 2.566 5.027" /><path d="M8 15m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" /><path d="M10 15v-2a2 2 0 1 1 4 0v2" /></svg>
                                    </div>
                                    <h5 className='mb-3'>Secure and Compliant Transactions</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>- Advanced Security: Protect your data with cutting-edge encryption and secure servers.</li>
                                        <li className='list-item'>- Regulatory Compliance: Our platform ensures all transactions comply with GDPR, CCPA, and international data protection laws.</li>
                                        <li className='list-item'>- Ethical Standards: Promote responsible data sharing while respecting privacy and consent.</li>
                                    </ul>
                                </div>
                            </div>


                        </div>

                    </div>

                </section>

                <section id="demos" className="iq-homepage position-relative  main-bg">
                    <div className="container">

                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="title-box text-center mb-0">
                                    <h2 className='mb-0'>For Data Buyers: Access High-Quality Data to Propel Your Business Forward</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center pt-5">
                            <div className="col-md-6 text-center" data-aos="fade-up">
                                <div className="homepage-block h-100 h-100">
                                    <div className="home-block shadow bg-white p-4 h-100">
                                        <h5 className='mb-3'>Unlock Valuable Data Assets</h5>
                                        <ul className='list-unstyled'>
                                            <li>Diverse Data Sources: Discover unique datasets tailored to your industry and specific needs.
                                            </li>
                                            <li>Accelerate Growth: Use high-quality data to inform strategies, improve products, and enter new markets.
                                            </li>
                                            <li>Competitive Edge: Gain insights that are not available to your competitors.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-center" data-aos="fade-up">
                                <div className="homepage-block h-100 h-100">
                                    <div className="home-block shadow bg-white p-4 h-100">
                                        <h5 className='mb-3'>Why Buy from Our Marketplace?</h5>
                                        <ul className='list-unstyled'>
                                            <li>Direct Access to Sellers: Engage directly with data owners for custom data solutions.</li>
                                            <li>Cost Efficiency: Avoid inflated prices by purchasing directly from the source.</li>
                                            <li>Quality Assurance: All data is vetted for accuracy, relevance, and compliance.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="iq-retina " id="features">
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className="col-lg-6 col-md-12 wow fadeInLeft">
                                <div className="img-box rounded">
                                    <img src="https://bootstrapmade.com/content/demo/Scaffold/assets/img/working-1.jpg" className="img-fluid rounded" alt="#" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12  wow fadeInRight">
                                <div className="title-box text-left p-4">
                                    <span className="main-color">Join a Thriving Global Community</span>
                                    <h2 className="title mb-4">Take Control Today and Start Generating Revenue</h2>
                                    <ul>
                                        <li>- Over 150,000 registered users worldwide.</li>
                                        <li>- Billions of data points exchanged securely and ethically.</li>
                                        <li>- Supportive Network: Access resources, webinars, and expert advice to maximize your success.</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="main-bg iq-blog">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="title-box text-left p-4">
                                    <h2 className="title mb-4">Data Market Insights</h2>
                                    <ul className='list-unstyled'>
                                        <li>- $655 Billion: Expected global big data and analytics market value by 2029 (Fortune Business Insights, 2022).</li>
                                        <li>- $200 Billion: Estimated global data brokerage revenue by 2025 (Forbes).</li>
                                        <li>- 90%: Percentage of the world's data generated in the last two years, highlighting the exponential growth and opportunity (IBM).</li>


                                    </ul>

                                    <p>Don't let your valuable data enrich others while you miss out. Take control and profit from your data today.</p>

                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <img src="https://bootstrapmade.com/content/demo/Scaffold/assets/img/about.jpg" alt="" className='img-fluid rounded' />
                            </div>
                        </div>
                    </div>

                </section>
                <section className="iq-innerpages" id="inner">
                    <div className="container">
                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="title-box text-center ">
                                    <h2 className="title">Use Cases and Real-World Applications for Data Buyers</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Healthcare Industry</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Predictive Analytics: Acquire patient trend data (anonymized and compliant) to predict disease outbreaks and improve preventive care.</li>
                                            <li>- Drug Development: Utilize clinical trial data to accelerate the development of new medications.</li>
                                            <li>- Telemedicine Enhancement: Leverage usage data to optimize remote healthcare services.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Financial Services</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Risk Assessment: Access credit and financial behavior data to refine credit scoring models and reduce default rates.</li>
                                            <li>- Fraud Detection: Implement transaction data to identify and prevent fraudulent activities in real-time.</li>
                                            <li>- Investment Strategies: Use market trend data to inform investment decisions and portfolio management.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Retail and E-Commerce</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Customer Behavior Analysis: Purchase consumer purchasing data to personalize marketing campaigns and improve customer retention.</li>
                                            <li>- Inventory Management: Utilize sales and trend data to optimize stock levels, reducing overstock and stockouts.</li>
                                            <li>- Price Optimization: Analyze competitor pricing data to adjust strategies dynamically.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Marketing and Advertising</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Targeted Campaigns: Access demographic and psychographic data to create highly targeted and effective advertising campaigns.</li>
                                            <li>- Market Research: Obtain consumer feedback data to understand brand perception and customer satisfaction.</li>
                                            <li>- Trend Analysis: Use social media and search data to stay ahead of emerging trends.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Technology and AI Development</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Machine Learning Training: Acquire large, high-quality datasets to train AI models for image recognition, NLP, and more.</li>
                                            <li>- Algorithm Improvement: Use diverse data to reduce bias and improve the accuracy of predictive models.</li>
                                            <li>- Product Development: Leverage user interaction data to enhance software and application features.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Transportation and Logistics</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Route Optimization: Utilize real-time traffic and weather data to improve delivery efficiency and reduce costs.</li>
                                            <li>- Fleet Management: Access vehicle performance data for predictive maintenance and enhanced safety.</li>
                                            <li>- Demand Forecasting: Use historical shipping data to anticipate demand and adjust operations accordingly.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Energy and Utilities</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Demand Response: Implement consumption data to optimize energy distribution and reduce waste.</li>
                                            <li>- Renewable Energy Integration: Use weather and performance data to enhance the efficiency of renewable energy sources.</li>
                                            <li>- Customer Usage Patterns: Analyze data to offer personalized energy-saving recommendations.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Real Estate</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Property Valuation: Access market data to accurately assess property values and investment opportunities.</li>
                                            <li>- Urban Development Planning: Utilize demographic and economic data to inform development projects.</li>
                                            <li>- Risk Assessment: Use environmental and historical data to evaluate risks like flooding or seismic activity.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Agriculture</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Precision Farming: Leverage soil, weather, and crop data to optimize planting schedules and resource usage.</li>
                                            <li>- Supply Chain Optimization: Use market demand data to plan crop distribution and reduce waste.</li>
                                            <li>- Sustainability Practices: Implement data on resource usage to improve environmental impact.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Government and Public Sector</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Urban Planning: Utilize traffic and population data to improve infrastructure and public services.</li>
                                            <li>- Public Health Initiatives: Access health trend data to plan and implement effective health campaigns.</li>
                                            <li>- Disaster Management: Use environmental and geographic data for emergency preparedness and response.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-center mb-3" data-aos="fade-up">
                                <div className="homepage-block h-100">
                                    <div className="home-block shadow p-4 h-100">
                                        <h5 className="home-tag mb-5 mb-lg-3">Education</h5>
                                        <ul className='list-unstyled'>
                                            <li>- Personalized Learning: Use student performance data to tailor educational programs and improve outcomes.</li>
                                            <li>- Resource Allocation: Analyze enrollment and usage data to optimize resource distribution.</li>
                                            <li>- Research Advancement: Access specialized datasets to support academic research and innovation.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className='container'>

                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-12">
                                <div className="title-box text-center ">
                                    <h2 className="title">How it Works?</h2>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 mx-auto'>
                                <div className="stepper d-flex flex-column mt-5 me-2">
                                    <div className="d-flex mb-1">
                                        <div className="how-circle py-2 px-3 mb-1">1</div>
                                        <div className='ms-3'>
                                            <h5 className="text-dark">Quick and Easy Registration</h5>
                                            <ul className='list-unstyled'>
                                                <li className='pb-1 lead text-muted'>Simple Sign-Up: Join using your email or social media accounts.</li>
                                                <li className='pb-1 lead text-muted'>Secure Verification: Complete our swift verification process to ensure a trusted marketplace.</li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <div className="how-circle py-2 px-3 mb-1">2</div>
                                        <div className='ms-3'>
                                            <h5 className="text-dark">For Sellers: List and Commercialize Your Data</h5>
                                            <ul className='list-unstyled'>
                                                <li className='pb-1 lead text-muted'>Effortless Listing: Upload your datasets securely or provide detailed descriptions for interested buyers.</li>
                                                <li className='pb-1 lead text-muted'>Set Your Terms: Choose pricing models, define usage rights, and establish licensing agreements.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <div className="how-circle py-2 px-3 mb-1">3</div>
                                        <div className='ms-3'>
                                            <h5 className="text-dark"> For Buyers: Find and Acquire the Data You Need</h5>
                                            <ul className='list-unstyled'>
                                                <li className='pb-1 lead text-muted'>Advanced Search Tools: Filter by industry, data type, or specific keywords to find the perfect dataset.
                                                </li>
                                                <li className='pb-1 lead text-muted'>Preview Before Purchase: Access data samples or summaries to assess quality and relevance.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <div className="how-circle py-2 px-3 mb-1">4</div>
                                        <div className='ms-3'>
                                            <h5 className="text-dark">Secure and Transparent Transactions</h5>
                                            <ul className='list-unstyled'>
                                                <li className='pb-1 lead text-muted'>Protected Negotiations: Communicate and negotiate terms within our encrypted platform.</li>
                                                <li className='pb-1 lead text-muted'>Guaranteed Payments: Our escrow service ensures funds are securely transferred upon successful data exchange.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section id="services" className="services section">


                    <div className="container section-title aos-init aos-animate pb-4" data-aos="fade-up">
                        <h2 className='text-center pb-2'>What Makes Us Stand Out?</h2>
                    </div>
                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-activity">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M3 12h4l3 8l4 -16l3 8h4" />
                                        </svg>
                                    </div>
                                    <h5 className='mb-3'>Transparent and Fair Marketplace</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>No Hidden Fees: Clear and straightforward pricing—you keep what you earn.</li>
                                        <li className='list-item'>Detailed Analytics: Monitor your performance with real-time data on views, inquiries, and sales.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-math-max-min"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M3 14s.605 -5.44 2.284 -7.862m3.395 .026c2.137 2.652 4.547 9.113 6.68 11.719" /><path d="M18.748 18.038c.702 -.88 1.452 -3.56 2.252 -8.038" /></svg>
                                    </div>
                                    <h5 className='mb-3'>Innovative Platform Features</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>AI-Driven Matchmaking: Our system connects sellers and buyers based on data needs and offerings.</li>
                                        <li className='list-item'>Personalized Alerts: Stay informed about new opportunities that match your interests.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-world"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M3.6 9h16.8" /><path d="M3.6 15h16.8" /><path d="M11.5 3a17 17 0 0 0 0 18" /><path d="M12.5 3a17 17 0 0 1 0 18" /></svg>
                                    </div>
                                    <h5 className='mb-3'>Exceptional Support</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>Dedicated Account Managers: Personalized assistance to help you navigate the platform and maximize revenue.</li>
                                        <li className='list-item'>Educational Resources: Access a library of tutorials, market insights, and best practices.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="icon mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#437eeb" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-cloud-lock"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M19 18a3.5 3.5 0 0 0 0 -7h-1c.397 -1.768 -.285 -3.593 -1.788 -4.787c-1.503 -1.193 -3.6 -1.575 -5.5 -1s-3.315 2.019 -3.712 3.787c-2.199 -.088 -4.155 1.326 -4.666 3.373c-.512 2.047 .564 4.154 2.566 5.027" /><path d="M8 15m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" /><path d="M10 15v-2a2 2 0 1 1 4 0v2" /></svg>
                                    </div>
                                    <h5 className='mb-3'>Uncompromised Security</h5>
                                    <ul className='list-unstyled'>
                                        <li className='list-item'>Multi-Layered Protection: Beyond encryption, we offer two-factor authentication and regular security audits.
                                        </li>
                                        <li className='list-item'>Compliance Monitoring: We stay up-to-date with global regulations to protect you and your data.</li>
                                    </ul>
                                </div>
                            </div>


                        </div>

                    </div>

                </section>



                <section className="testimonials-clean">
                    <div className="container-fluid">
                        <div className="intro">
                            <h2 className="text-center">Testimonials from Our Community</h2>
                        </div>
                        <div className="row people">
                            <div className="col-md-6 col-lg-3 item">
                                <div className="box">
                                    <p className="description">Directly commercializing our data has transformed our financial outlook and provided us with greater control over our assets.</p>
                                </div>
                                <div className="author"><img className="rounded-circle" src="img/2.jpg" />
                                    <h5 className="name">Sophia L</h5>
                                    <p className="title">COO of Manufacturing Corp</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 item">
                                <div className="box">
                                    <p className="description">Purchasing data directly from the source has cut our costs and improved the quality of our analytics.</p>
                                </div>
                                <div className="author"><img className="rounded-circle" src="img/3.jpg" />
                                    <h5 className="name">Thomas E.</h5>
                                    <p className="title">CEO of Market Insights Ltd.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 item">
                                <div className="box">
                                    <p className="description">This platform empowered me to understand the true value of my data and capitalize on it effectively.</p>
                                </div>
                                <div className="author"><img className="rounded-circle" src="img/1.jpg" />
                                    <h5 className="name">Michael T.</h5>
                                    <p className="title">Freelance Developer</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 item">
                                <div className="box">
                                    <p className="description">We gained access to niche datasets that were crucial for our AI training models, directly impacting our product's success.</p>
                                </div>
                                <div className="author"><img className="rounded-circle" src="img/4.jpg" />
                                    <h5 className="name">Aisha K.</h5>
                                    <p className="title">CTO of AI Innovations</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>



                <section id="faq" className="faq section light-background bg-white">


                    <div className="container section-title aos-init aos-animate mb-4" data-aos="fade-up">
                        <h2 className='text-center mb-0 pb-2'>Frequently Asked Questions</h2>
                    </div>

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-lg-8">

                                <div className="faq-container">

                                    <div className="faq-item aos-init aos-animate" data-aos="fade-up">

                                        <div className='d-flex align-items-center justify-content-between pointer' onClick={() => setFaqIndex(0)}>
                                            <h3>How do I start commercializing my data?</h3>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                        </div>
                                        {
                                            faqindex === 0 && <div className="faq-content mt-3">
                                                <p>Simply sign up, complete the verification process, and start listing your data. You control pricing and terms.</p>
                                            </div>
                                        }


                                    </div>

                                    <div className="faq-item aos-init aos-animate" data-aos="fade-up">

                                        <div className='d-flex align-items-center justify-content-between pointer' onClick={() => setFaqIndex(1)}>
                                            <h3>Is my data safe on your platform?</h3>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                        </div>
                                        {
                                            faqindex === 1 && <div className="faq-content mt-3">
                                                <p>Absolutely. We use advanced encryption, secure servers, and comply with international data protection laws to ensure your data is protected.</p>
                                            </div>}
                                    </div>

                                    <div className="faq-item aos-init aos-animate" data-aos="fade-up">

                                        <div className='d-flex align-items-center justify-content-between pointer' onClick={() => setFaqIndex(2)}>
                                            <h3>Can I sell the same data to multiple buyers?</h3>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                        </div>
                                        {
                                            faqindex === 2 && <div className="faq-content mt-3">
                                                <p>Yes, unless you agree to an exclusive deal, you're free to sell your data to as many buyers as you like.</p>
                                            </div>}
                                    </div>

                                    <div className="faq-item aos-init aos-animate" data-aos="fade-up">

                                        <div className='d-flex align-items-center justify-content-between pointer' onClick={() => setFaqIndex(3)}>
                                            <h3>What types of data are in demand?</h3>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                        </div>
                                        {
                                            faqindex === 3 && <div className="faq-content mt-3">
                                                <p>High-demand data includes consumer behavior, market trends, financial analytics, healthcare data, and more. However, niche data can also be highly valuable.</p>
                                            </div>}
                                    </div>

                                    <div className="faq-item aos-init aos-animate" data-aos="fade-up">
                                        <div className='d-flex align-items-center justify-content-between pointer' onClick={() => setFaqIndex(4)}>
                                            <h3>How do I know how much to charge for my data?</h3>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                        </div>

                                        {
                                            faqindex === 4 && <div className="faq-content mt-3">
                                                <p>Our platform provides market insights and analytics to help you price your data competitively.
                                                </p>
                                            </div>}
                                    </div>

                                    <div className="faq-item aos-init aos-animate" data-aos="fade-up">
                                        <div className='d-flex align-items-center justify-content-between pointer' onClick={() => setFaqIndex(5)}>
                                            <h3>What payment methods are available?</h3>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                        </div>
                                        {
                                            faqindex === 5 && <div className="faq-content mt-3">
                                                <p>We support various payment options, including bank transfers, PayPal, and cryptocurrencies for your convenience.
                                                </p>
                                            </div>
                                        }
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>

            </div>



        </>
    );
};

export default HomeScreen;