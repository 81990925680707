import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { resetPassword } from '../actions/userActions';
import { validatePassword } from '../utils/validation';

const ResetPasswordScreen = ({ match, history }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(null);

    const dispatch = useDispatch();

    const userResetPassword = useSelector((state) => state.userResetPassword);
    const { loading, error, success } = userResetPassword;

    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
        } else if (!validatePassword(password)) {
            setMessage('Password should be at least 8 characters long, contain 1 uppercase, 1 lowercase and 1 number');
        } else {
            dispatch(resetPassword(password, match.params.resettoken));
        }
    };

    useEffect(() => {
        if (success) {
            history.push('/login');
        }
    }, [history, success]);

    return (

        <div className="main-content pt-5">
            <div className='my-5 py-5'>
        <FormContainer>
            <h1>Reset Password</h1>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {success && <Message variant='success'>Password has been reset</Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='password'>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Enter new password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='confirmPassword'>
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Confirm new password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary' className='mt-3 shadow w-100'>
                    Reset Password
                </Button>
            </Form>
        </FormContainer>
        </div>
        </div>
    );
};

export default ResetPasswordScreen;