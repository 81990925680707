import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { login } from '../actions/userActions';
import { validateEmail } from '../utils/validation';


const LoginScreen = ({ location, history }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, userInfo } = userLogin;

    const redirect = location.search ? location.search.split('=')[1] : '/';

    useEffect(() => {
        if (userInfo) {
            history.push(redirect);
        }
    }, [history, userInfo, redirect]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            dispatch({ type: 'USER_LOGIN_FAIL', payload: 'Invalid email format' });
        } else {
            dispatch(login(email, password));
        }
    };

    return (
        <div className="main-content pt-5">
            <div className='my-5 py-5'>
            <FormContainer>
                <div className='row'>
                    <div className='col-md-10 mx-auto'>
                        <div className='bg-white p-3 shadow-sm rounded'>
                        <h4 className='text-center border-bottom-ef py-3 mb-3'>Sign In</h4>
                        
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='email'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder='Enter password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                    </Form.Group>

                    <Button type='submit' variant='primary' className='mt-3 shadow w-100 py-2'>
                        Sign In
                    </Button>
                </Form>

                <Row className='py-3'>
                    <Col>
                        New Customer?{' '}
                        <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                            Register
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Forgot Password?{' '}
                        <Link to='/forgotpassword'>Reset Password</Link>
                    </Col>
                </Row>
                        </div>
                    </div>
                </div>
            </FormContainer>
            </div>
        </div>
    );
};

export default LoginScreen;