export const DATASET_LIST_REQUEST = 'DATASET_LIST_REQUEST'
export const DATASET_LIST_SUCCESS = 'DATASET_LIST_SUCCESS'
export const DATASET_LIST_FAIL = 'DATASET_LIST_FAIL'

export const DATASET_DETAILS_REQUEST = 'DATASET_DETAILS_REQUEST'
export const DATASET_DETAILS_SUCCESS = 'DATASET_DETAILS_SUCCESS'
export const DATASET_DETAILS_FAIL = 'DATASET_DETAILS_FAIL'

export const DATASET_DELETE_REQUEST = 'DATASET_DELETE_REQUEST'
export const DATASET_DELETE_SUCCESS = 'DATASET_DELETE_SUCCESS'
export const DATASET_DELETE_FAIL = 'DATASET_DELETE_FAIL'

export const DATASET_CREATE_REQUEST = 'DATASET_CREATE_REQUEST'
export const DATASET_CREATE_SUCCESS = 'DATASET_CREATE_SUCCESS'
export const DATASET_CREATE_FAIL = 'DATASET_CREATE_FAIL'
export const DATASET_CREATE_RESET = 'DATASET_CREATE_RESET'

export const DATASET_UPDATE_REQUEST = 'DATASET_UPDATE_REQUEST'
export const DATASET_UPDATE_SUCCESS = 'DATASET_UPDATE_SUCCESS'
export const DATASET_UPDATE_FAIL = 'DATASET_UPDATE_FAIL'
export const DATASET_UPDATE_RESET = 'DATASET_UPDATE_RESET'

export const DATASET_UPLOAD_REQUEST = 'DATASET_UPLOAD_REQUEST'
export const DATASET_UPLOAD_SUCCESS = 'DATASET_UPLOAD_SUCCESS'
export const DATASET_UPLOAD_FAIL = 'DATASET_UPLOAD_FAIL'
export const DATASET_UPLOAD_RESET = 'DATASET_UPLOAD_RESET'


export const DATASET_LIST_MY_REQUEST = 'DATASET_LIST_MY_REQUEST'
export const DATASET_LIST_MY_SUCCESS = 'DATASET_LIST_MY_SUCCESS'
export const DATASET_LIST_MY_FAIL = 'DATASET_LIST_MY_FAIL'
