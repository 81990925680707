
import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { createDataset } from '../actions/datasetActions';
import { DATASET_CREATE_RESET } from '../constants/datasetConstants';

const CreateListingScreen = ({ history }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const datasetCreate = useSelector(state => state.datasetCreate);
  const { loading, error, success } = datasetCreate;

  useEffect(() => {
    if (success) {
      dispatch({ type: DATASET_CREATE_RESET });
      history.push('/dashboard');
    }
  }, [dispatch, history, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('file', file);
    dispatch(createDataset(formData));
  };

  return (
    <FormContainer>
      <h1>Create New Listing</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='title'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId='description'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              placeholder='Enter description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId='price'>
            <Form.Label>Price</Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter price'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId='file'>
            <Form.Label>Dataset File</Form.Label>
            <Form.Control
              type='file'
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Form.Group>

          <Button type='submit' variant='primary'>
            Create Listing
          </Button>
        </Form>
      )}
    </FormContainer>
  );
};

export default CreateListingScreen;