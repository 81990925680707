import {
    DATASET_LIST_REQUEST,
    DATASET_LIST_SUCCESS,
    DATASET_LIST_FAIL,
    DATASET_DETAILS_REQUEST,
    DATASET_DETAILS_SUCCESS,
    DATASET_DETAILS_FAIL,
    DATASET_DELETE_REQUEST,
    DATASET_DELETE_SUCCESS,
    DATASET_DELETE_FAIL,
    DATASET_CREATE_RESET,
    DATASET_CREATE_FAIL,
    DATASET_CREATE_SUCCESS,
    DATASET_CREATE_REQUEST,
    DATASET_UPDATE_REQUEST,
    DATASET_UPDATE_SUCCESS,
    DATASET_UPDATE_FAIL,
    DATASET_UPDATE_RESET,
    DATASET_UPLOAD_REQUEST,
    DATASET_UPLOAD_SUCCESS,
    DATASET_UPLOAD_FAIL,
    DATASET_UPLOAD_RESET,
} from '../constants/datasetConstants'

export const datasetListReducer = (state = { datasets: [] }, action) => {
    switch (action.type) {
        case DATASET_LIST_REQUEST:
            return { loading: true, datasets: [] }
        case DATASET_LIST_SUCCESS:
            return {
                loading: false,
                datasets: action.payload.datasets,
                pages: action.payload.pages,
                page: action.payload.page,
            }
        case DATASET_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const datasetDetailsReducer = (state = { dataset: {} }, action) => {
    switch (action.type) {
        case DATASET_DETAILS_REQUEST:
            return { ...state, loading: true }
        case DATASET_DETAILS_SUCCESS:
            return { loading: false, dataset: action.payload }
        case DATASET_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const datasetDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DATASET_DELETE_REQUEST:
            return { loading: true }
        case DATASET_DELETE_SUCCESS:
            return { loading: false, success: true }
        case DATASET_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const datasetCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DATASET_CREATE_REQUEST:
            return { loading: true }
        case DATASET_CREATE_SUCCESS:
            return { loading: false, success: true, dataset: action.payload }
        case DATASET_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case DATASET_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const datasetUpdateReducer = (state = { dataset: {} }, action) => {
    switch (action.type) {
        case DATASET_UPDATE_REQUEST:
            return { loading: true }
        case DATASET_UPDATE_SUCCESS:
            return { loading: false, success: true, dataset: action.payload }
        case DATASET_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case DATASET_UPDATE_RESET:
            return { dataset: {} }
        default:
            return state
    }
}

export const datasetUploadReducer = (state = {}, action) => {
    switch (action.type) {
        case DATASET_UPLOAD_REQUEST:
            return { loading: true }
        case DATASET_UPLOAD_SUCCESS:
            return { loading: false, success: true, dataset: action.payload }
        case DATASET_UPLOAD_FAIL:
            return { loading: false, error: action.payload }
        case DATASET_UPLOAD_RESET:
            return {}
        default:
            return state
    }
}