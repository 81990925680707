import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import DatasetScreen from './screens/DatasetScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import UploadDatasetScreen from './screens/UploadDatasetScreen';
import EmailVerificationScreen from './screens/EmailVerificationScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import PrivateRoute from './components/PrivateRoute';
import ErrorBoundary from './components/ErrorBoundary';

import AOS from 'aos';
import 'aos/dist/aos.css';
import DashboardScreen from './screens/Dashboard';
import CreateListingScreen from './screens/CreateListing';

const App = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  return (
    <ErrorBoundary>
      <Router>
        <Header />
          <Switch>
            <Route path='/login' component={LoginScreen} />
            <Route path='/register' component={RegisterScreen} />
            <PrivateRoute path='/profile' component={ProfileScreen} />
            <Route path='/dashboard' component={DashboardScreen} />
            <Route path='/create-listing' component={CreateListingScreen} />
            <Route path='/dataset/:id' component={DatasetScreen} />
            <PrivateRoute path='/upload' component={UploadDatasetScreen} />
            <Route path='/search/:keyword' component={HomeScreen} exact />
            <Route path='/page/:pageNumber' component={HomeScreen} exact />
            <Route path='/search/:keyword/page/:pageNumber' component={HomeScreen} exact />
            <Route path='/verify/:token' component={EmailVerificationScreen} />
            <Route path='/forgotpassword' component={ForgotPasswordScreen} />
            <Route path='/resetpassword/:resettoken' component={ResetPasswordScreen} />
            <Route path='/' component={HomeScreen} exact />
          </Switch>
        <Footer />
      </Router>
    </ErrorBoundary>
  );
};

export default App;
